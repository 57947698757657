import React from 'react'
import { useForm } from "react-hook-form";
import axios from 'axios';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useState,useRef } from 'react';
import '../components/rform.css'
import { useCookies } from 'react-cookie';

const CreateBlog = () => {
  const [cookies,setCookie] = useCookies('token')
    const submitBlog = async data=>{
      var res = {}
      var blogId = ""
        await axios.post('https://sqmerchantservices.com:8443/blogs',data).then((response)=>{
          res = response.data;
          blogId = response.data._id
        });
        console.log(res);
        console.log(blogId);
        const url = 'http://localhost:8000/fileUp';
        const formData = new FormData();
        formData.append('blogId', blogId);
        formData.append('image', image);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        axios.post(url, formData, config).then((response) => {
          console.log(response.data);

    })
    reset();
  }

    const [image,setImage] = useState()
     // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);
  
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    setImage(fileUploaded);
  };
  
    const { register, handleSubmit, reset,formState: { errors }, } = useForm({});
    if (!cookies.token){
      return (
        <h2 className='regForm'>Authenitcation error please press the back button</h2>
      )
    };
  return (
  <div className="regForm">
    <Form className="regForm" onSubmit={handleSubmit(submitBlog)}>
    <Form.Group className="mb-3">
      <Form.Label>Title</Form.Label>
      <Form.Control type="text" placeholder="Enter Blog Title"{...register('title')} />
    </Form.Group>
    <Form.Group className="mb-3">
      <Form.Label>Content</Form.Label>
      <Form.Control className="pb-5" type="textfield" placeholder="Blog Content" {...register('body')}/>
    </Form.Group>

    <Form.Group className="mb-3">
      <Form.Label>Author</Form.Label>
      <Form.Control type="text" placeholder="User Name" {...register('author')}/>
    </Form.Group>
    <Button variant="primary" className="button-upload" onClick={handleClick}>
        Upload a file
      </Button>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{display: 'none'}} // Make the file input element invisible
      />

    <Button className="m-3" variant="primary" type="submit">
      Submit
    </Button>
  </Form>
  </div>
  )
}

export default CreateBlog