import { useState, useEffect } from 'react';
import React from 'react';
import './blog.css';
import {Card} from 'react-bootstrap';
import axios from 'axios';




const Blog = () => {
  const [blogs, setBlogs] = useState([])
  useEffect(() =>{
    axios.get('https://sqmerchantservices.com:8443/blogs')
   .then(res => {
    setBlogs(res.data)
    console.log(res.data)
    })
   .catch(err => console.log(err))    
  })
  return (
    <div>
          <div className="blogDiv1">
          <h1 className="blog-text">SQMS Blog</h1>
    </div>
    <div  className="blogDiv">
      {blogs.map((blog) => (
        <Card style={{ width: '70rem'}} className="bg-secondary text-center text-white mt-4 p-1" key={blog._id} >
        <Card.Img className="pt-3 pb-3" src={blog.img} alt="Card image" />
        <Card.Title className="mt-4">{blog.title}</Card.Title>
        <Card.Text className="mt-3">
          {blog.body}
        </Card.Text>
        </Card>
      ))}
      </div>
      </div>
  )
}

export default Blog