import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {Link} from 'react-router-dom';
import testImg from '../assets/sq-logo-four-1.png';
import './header.css';

function Header() {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <div>
      <Button className='ov-btn' variant="primary" onClick={handleShow}>
        Open Menu
      </Button>

      <Offcanvas className='offMenu' show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link onClick={handleClose} to='/'>
               <img src={testImg} alt='' className='nav-img' />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='item-cont'>
            <Link onClick={handleClose} className='nav-item' to='/About'>
              About Us
            </Link>
            <Link onClick={handleClose} className='nav-item' to='/Contact'>
              Contact Sales
            </Link>
            <Link onClick={handleClose} className='nav-item' to='/Partners'>
              Our Partners
            </Link>
            <Link onClick={handleClose} className='nav-item' to='/Testimonials'>
              Why Use SQMS?
            </Link>
            <Link onClick={handleClose} className='nav-item' to='/Blog'>
              Blog
            </Link>
	    <Link hidden='true' onClick={handleClose} className='nav-item' to='/createblog'>
              Blog
            </Link>
          </div>
          <img  alt="SQ Merchant Services BBB Business Review" data-src="https://i0.wp.com/seal-alaskaoregonwesternwashington.bbb.org/logo/ruhzbum/bbb-90003098.png?w=1080&amp;ssl=1" class=" lazyloaded" src="https://i0.wp.com/seal-alaskaoregonwesternwashington.bbb.org/logo/ruhzbum/bbb-90003098.png?w=1080&amp;ssl=1" loading="lazy" width="300" height="58"></img>
          <p className="header-contact">Business Address: <br/>15532 SW Pacific Hwy C1B #235 Tigard, OR 97224 <br/> Contact Number:<br/>971-225-7767<br/>Business Email:<br/> info@sqms.biz<br/>24/7 Tech Support @ 541-209-6538</p>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default Header
